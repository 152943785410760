import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button from "../components/Button";
import GhostButton from "../components/GhostButton";
import ScreenWidths from "../constants/ScreenWidths";
import {useForm} from "react-hook-form";
import InputWrapper from "../components/InputWrapper";
import InputErrorMsg from "../components/InputErrorMsg";
import JsonApi from "../../components/JsonApi";
import config from "../../static/config";
import jwt from "jsonwebtoken";
import RegexStr from "../constants/RegexStr";

const StyLgEmail = styled.div.attrs({className: 'lg-email'})`
    text-align: left;
    > p{
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 600;
        @media (width < ${ScreenWidths.xs}) {
            font-size: 20px;
        }
    }
    
    .email-lg-btn {
        margin: 8px 0 20px 0;
    }
`;

const LgEmail = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({mode: "all"});
    const [credentialsMsg, setCredentialsMsg] = useState("")
    const [mfaRequired, setMfaRequired] = useState();
    const [loadingQuery, setLoadingQuery] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        localStorage.removeItem('auth-token');
    },[])

    const onSubmit = data => {
        setLoadingQuery(true)
        new JsonApi(config.BACKEND_URL, false).post('/auth/auth/database', { username: data.username, password: data.password})
            .then(json => {
                setLoadingQuery(false)
                const decoded = jwt.decode(json.accessToken);
                if (decoded != null) {
                    if (((decoded.applications || []).filter(app => app.includes('Cardholder'))).length > 0) {
                        localStorage.setItem('auth-token', json.accessToken);
                        navigate("/home");
                    } else {
                        setCredentialsMsg(t('Invalid user or user not authorized to access the application'));
                    }
                }
                reset();
            }).catch(error => {
            if (error.json) {
                setLoadingQuery(false)
                error.json().then(json => {
                    const message = json['error_messages'] ? json['error_messages'].join('\n') : json['error_message'];
                    if ('MFA is required' === message) {
                        setMfaRequired(true);
                    } else {
                        message ? setCredentialsMsg(t(message)) : setCredentialsMsg(t("Invalid username or password"));
                    }
                });
            } else {
                setLoadingQuery(false)
                console.log(t('Could not fetch data from the server'));
            }
            reset();
        });
    };


    return (
        <StyLgEmail {...props}>
            <p>{t("Log in with username")}</p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper error={errors.username} label={t("Username")} value={watch("username")}>
                    <input
                        autoComplete={false}
                        {...register("username", {
                            required: "This field is required",
                            pattern: { value: RegexStr.hasBlankSpace, message: "Blank spaces are not allowed"}
                        })}
                    />
                </InputWrapper>

                <InputWrapper error={errors.password} label={t("Password")} value={watch("password")} viewPassword={() => setShowPassword(!showPassword)}>
                    <input
                        autoComplete={false}
                        className={"eye"}
                        type={ showPassword ? "text" : "password"}
                        {...register("password", {
                            required: "This field is required",
                            pattern: { value: RegexStr.hasBlankSpace, message: "Blank spaces are not allowed"}
                        })}
                    />
                </InputWrapper>

                { credentialsMsg && <InputErrorMsg label={credentialsMsg} justify={"center"}/>}

                <Button className={"email-lg-btn"} type={"submit"} onClick={() => { setCredentialsMsg(null)}} loading={loadingQuery}>
                    <div>{t("Log in")}</div>
                </Button>

                <GhostButton label={t("Forgot your password?")} onClick={() => navigate("/login/email-recover")}/>

            </form>
        </StyLgEmail>
    )
};

export default LgEmail;